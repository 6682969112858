<template>
  <div>
    <label
      v-for="date in deliveryDays"
      :key="date.id"
      class="form-check form-check-inline">
      <input
        class="form-check-input"
        :checked="!!days.find(item => item.id === date.id)"
        type="checkbox"
        @change="onchange(date)">
      <span class="form-check-label">{{date.name}}</span>
    </label>
  </div>
</template>

<script>
import {deliveryDays} from '@/services';
import {toggleArray} from '@hellochef/shared-js/helpers';

export default {
  data() {
    return {
      days: [],
      deliveryDays: [],
    };
  },
  created() {
    deliveryDays.getByParameters({column: 'id', direction: 'asc'}).then(result => this.deliveryDays = result.items);
  },
  methods: {
    onchange(date) {
      toggleArray(this.days, date, (array, selected) => array.id === selected.id);
      this.$emit('on-change', this.days);
    },
  },
};
</script>
