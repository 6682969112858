<template>

  <treeselect
    v-model="selected"
    class="custom-treeselect"
    :normalizer="normalizer"
    valueFormat="object"
    :options="options"
    :placeholder="placeholder"
    @close="onClose"
    @open="onOpen"
    @search-change="query => onSearch(query)"
  >
    <div slot="value-label" slot-scope="{node}">{{node.raw.name}}</div>
    <div slot="after-list">
      <div
        v-show="hasNextPage"
        ref="load"
        class="text-center">
        Loading more {{entities}}...
      </div>
    </div>
  </treeselect>

</template>

<script>

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import debounce from 'debounce-promise';

export default {

  components: {

    Treeselect,
  },
  props: {

    disabled: {

      default: false,
      type: Boolean,
    },
    entity: {

      type: String,
    },
    flat: {

      default: false,
      type: Boolean,
    },
    label: {

      type: String,
    },
    list: {

      default: () => [],
      type: [Array, Object],
    },
    multiple: {

      default: false,
      type: Boolean,
    },
    nextPage: {

      type: Function,
    },
    normalizer: {

      default: node => {
        return {

          children: node ? node.children : [],
          label: node ? node.label : '',
        };
      },
      type: Function,
    },
    placeholder: {

      default: 'Select an item',
      type: String,
    },
    plural: {

      type: String,
    },
    value: {

      type: [String, Object, Array],
    },
  },
  data() {
    return {

      loading: false,
      observer: null,
      search: '',
    };
  },
  computed: {

    entities() {
      return this.plural || `${this.entity}s`;
    },
    hasNextPage() {
      return this.meta ? parseInt(this.meta.current_page) < parseInt(this.meta.last_page) : false;
    },
    meta() {
      return this.list.links;
    },
    options() {
      return this.list.items || [];
    },
    selected: {

      get() { return this.value; },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {

    async infiniteScroll([{isIntersecting, target}]) {
      if (isIntersecting) {
        this.loading = true;
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;

        const result = await this.nextPage(parseInt(this.meta.current_page || 1) + 1, this.search);

        this.list.items = this.list.items.concat(result.items || []);
        this.list.links = result.links;

        await this.$nextTick();
        ul.scrollTop = scrollTop;
        this.loading = false;
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onSearch: debounce(async function(query) {
      this.search = query;
      const result = await this.nextPage(1, query);

      this.list.items = result.items || [];
      this.list.links = result.links;
    }, 300),
  },
};

</script>

<style scoped>
    .custom-treeselect >>> .vue-treeselect__control {
        color: #495057;
        width: 100%;
        border: 1px solid rgba(0, 40, 100, 0.12);
        border-radius: 3px;
        background: #fff;
        font-size: .9375rem;
        line-height: 1.6;
        padding: 0 .75rem;
    }
</style>
