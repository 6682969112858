<template>
  <multi-tree-select
    v-model="selectedCategory"
    :list.sync="categories"
    :nextPage="nextCategories"
    :multiple="false"
    :normalizer="categoryNormalizer"
    label="name"
    placeholder="Select category"
    entity="category"
    plural="categories"
    @input="onSelect"
  />
</template>

<script>
import {categories} from '@/services';
import MultiTreeSelect from '@/components/MultiTreeSelect';

export default {
  components: {

    MultiTreeSelect,
  },
  data() {
    return {
      categories: [],
      selectedCategory: null,
      categoryNormalizer(node) {
        return {

          children: node ? node.sub_categories : [],
          label: node ? node.name : '',
        };
      },
    };
  },
  created() {
    categories.getByParameters({column: 'id', direction: 'asc'}).then(result => this.categories = result);
  },
  methods: {
    async nextCategories(page, query) {
      const params = {

        column: 'name',
        direction: 'asc',
        page,
      };

      if (query.length) params.query = query;

      const result = await categories.getByParameters(params);

      return result;
    },
    onSelect(value) {
      this.$emit('select', value);
    },
  },
};
</script>
